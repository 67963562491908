import React, { useState } from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import { DateTime } from "luxon";
import Imgix from "react-imgix";
import { motion } from "framer-motion";

import Layout from "../layouts/default";
import SEO from "../components/seo";

import { breakpoints } from "../styles/layouts";
import LayerIndex from "../utils/layer-index";
import Typography from '../styles/typography';

import { PageHeaderFluid } from "../components/page-header";
import { Section, ContainerFluid } from "../styles/layouts";
import Pagination from "../components/pagination";
import { NewBadge } from "../components/badge";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

library.add(faTimes);

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allWpScreenshot (
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          date
          screenshot {
            mainscreenshot {
              mediaDetails {
                file
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}

const Screenshot = ({ screenshot, selectHandler }) => {
  const now = DateTime.local();
  const screenshotDate = DateTime.fromISO(screenshot.date);
  const isNewest = (screenshotDate.plus({ days: 3 }) > now);

  const selectScreenshot = (e) => {
    e.preventDefault();
    selectHandler(screenshot);
  }

  return (
    <motion.div variants={item} css={screenshotStyle}>
      <a href="#" className="screenshot-pointer" onClick={selectScreenshot}>
        <h2 className="screenshot-title">{screenshot.title}</h2>
        <Imgix
          className="screenshot-image"
          src={`${process.env.GATSBY_WP_IMGIX_URL}/${screenshot.screenshot.mainscreenshot.mediaDetails.file}`}
          width={1280}
          height={1280}
          imgixParams={{ auto: 'compress,enhance,format', fit: "crop" }} />
      </a>
      <time className="screenshot-date" dateTime={screenshot.date}>
        { isNewest && <NewBadge top="10px" left="-12px" /> }
        {screenshotDate.toFormat('yyyy-MM-dd')}
      </time>
    </motion.div>
  );
}

const Modal = ({ screenshot, closeHander }) => {

  const closeModal = (e) => {
    e.preventDefault();
    closeHander(null);
  }

  return (<motion.div
    css={modalStyle}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{
      duration: 0.5
    }}>
      <a href="#" className="modal-close" onClick={closeModal}>
        <FontAwesomeIcon className="icon" icon={["fal", "times"]} title="close" />
      </a>
      <motion.div className="modal-inner">
        <Imgix
          className="modal-screenshot"
          src={`${process.env.GATSBY_WP_IMGIX_URL}/${screenshot.screenshot.mainscreenshot.mediaDetails.file}`}
          width={screenshot.screenshot.mainscreenshot.mediaDetails.width}
          height={screenshot.screenshot.mainscreenshot.mediaDetails.height}
          imgixParams={{
            auto: 'compress,enhance,format',
            txt: 'Copyright © SQUARE ENIX CO., LTD. All Rights Reserved.',
            'txt-align': 'bottom,left',
            'txt-size': 11,
            'txt-shad': 10,
            'txt-color': 'FFF',
            'txt-font': 'Futura Condensed'
          }} />
      </motion.div>
  </motion.div>);
}

export default ({ data, pageContext }) => {
  const [selectedScreenshot, setSelectedScreenshot] = useState(null);

  return (
    <>
      <Layout isscrolllock={!!selectedScreenshot}>
        <SEO title="スクリーンショット" description="FC Assort のスクリーンショットです。" />
        <main>
          <PageHeaderFluid title="Screenshots" css={galleryHeaderStyle} />
          <Section css={galleryStyle}>
            <ContainerFluid>
              <Pagination path={`/screenshots`} numPages={pageContext.numPages} currentPage={pageContext.currentPage} />
              <motion.div
                css={screenshotGrid}
                variants={container}
                initial="hidden"
                animate="visible">
                {data.allWpScreenshot.edges.map(({node}, index) => (
                  <Screenshot key={index} screenshot={node} selectHandler={setSelectedScreenshot} />
                ))}
              </motion.div>
              <Pagination path={`/screenshots`} numPages={pageContext.numPages} currentPage={pageContext.currentPage} />
            </ContainerFluid>
          </Section>
        </main>
      </Layout>
      { selectedScreenshot && <Modal screenshot={selectedScreenshot} closeHander={setSelectedScreenshot} /> }
    </>
  )
}

const modalStyle = css`
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${LayerIndex.modal};

  backdrop-filter: blur(4px);
  background: rgba(0,0,0,.75);

  .modal-inner {
    overflow: auto;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 2rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .modal-close {
    position: absolute;
    top: .5rem;
    right: .5rem;

    padding: 1rem;
    width: 1.5rem;

    text-align: center;
    color: #fff;
    font-size: 1.5rem;
    line-height: 1.5rem;

    border-radius: 50%;

    &:hover {
      background: #fff;
    }
  }

  .modal-screenshot {
    display: block;
    max-width: 85%;
    height: auto;

    @media ${breakpoints.handheld} {
      max-width: 100%;
    }

    box-shadow: 0px 32px 64px -32px rgba(0, 0, 0, 0.48), 0px 16px 128px -32px rgba(0, 0, 0, 0.24), 0px 24px 184px -48px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }
`;

const galleryHeaderStyle = css`
  background: #2B2D2E;
`;

const galleryStyle = css`
  background: #1D1E1F;
  color: #F0F3F4;
`;

const screenshotGrid = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 8rem;
  row-gap: 8rem;

  @media ${breakpoints.lg} {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5rem;
    row-gap: 5rem;
  }

  @media ${breakpoints.md} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5rem;
    row-gap: 5rem;
  }

  @media ${breakpoints.sm} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  }

  @media ${breakpoints.xs} {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0rem;
    row-gap: 3rem;
  }
`;

const screenshotStyle = css`
  position: relative;

  &:first-child {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;

    @media ${breakpoints.md} {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    @media ${breakpoints.sm} {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    @media ${breakpoints.xs} {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
  }

  .screenshot-pointer {
    color: #fff;

    &:hover .screenshot-title {
      color: #fff;
    }

    &:hover .screenshot-image {
      transform: scale(1.05);
      filter: brightness(100%);
    }
  }

  .screenshot-title {
    position: absolute;
    top: -1.5rem;
    left: -1rem;
    width: 75%;
    margin: 0;
    padding: 0;
    z-index: ${LayerIndex.overlay};

    ${Typography.h3};

    letter-spacing: .1em;
    word-break: keep-all;
    line-height: 1.5;

    @media ${breakpoints.handheld} {
      word-break: normal;
    }

    @media ${breakpoints.lg} {
      top: -1.5rem;
      left: -1rem;
    }

    @media ${breakpoints.md} {
      top: -1.5rem;
      left: -1rem;
    }

    @media ${breakpoints.sm} {
      top: -1rem;
      left: -1rem;
      width: 80%;
    }

    @media ${breakpoints.xs} {
      top: -1rem;
      left: -.5rem;
      width: 85%;
    }

    text-shadow:
      0 0 .75em rgba(0,0,0,.5),
      0 0 .1em rgba(0,0,0,.3);
  }

  .screenshot-date {
    display: block;
    position: absolute;
    bottom: 0;
    right: -.25rem;
    transform: rotate(90deg);
    transform-origin: bottom right;
    margin: 0;
    padding: 0;
    z-index: ${LayerIndex.overlay};

    @media ${breakpoints.xs} {
      right: 0;
    }

    white-space: nowrap;
    color: #ACAEAF;
    font-size: .75rem;
  }

  .screenshot-image {
    transition:
      transform .1s ease-in-out,
      filter .1s ease-in-out,
      box-shadow .1s ease-in-out;
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    filter: brightness(50%);

    box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.48), 0px 1px 4px -1px rgba(0, 0, 0, 0.24), 0px 1px 6px -2px rgba(0, 0, 0, 0.12);

    &:hover {
      box-shadow: 0px 32px 32px -32px rgba(0, 0, 0, 0.48), 0px 8px 64px -16px rgba(0, 0, 0, 0.24), 0px 12px 92px -24px rgba(0, 0, 0, 0.12);
    }
  }
`;
